
.review-attribution {
    display: flex;
    color: var(--subtle-detail-color-light-contrast);
    justify-content: flex-end;
    margin-right: 1em;
}

.review-attribution span {
    width: calc(75% - 3em);
    text-align: right;
    max-width: 20em;
}

.review-attribution img {
    height: 3em;
    width: auto;
    margin-left: 0.5em;
}

.review-form-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
}

.review-form-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.review-form-rating {
    display: block ruby;
}

.review-form .save {
    display: block ruby;
}

.review-form textarea {
    resize: unset;
}

.review-form-rating svg {
    width: 2em;
    height: 2em;
    margin: 0;
    padding: 0;
    display: inline-block;
}